<template>
  <div class="reports">
    <div class="table__header">
      <v-container fluid>
        <v-row class="pa-0">
          <v-col class="pl-0" cols="6">
            <h2 class="main-title">{{ $t("menu.reports") }}</h2>
            <total :total="COUNT" />
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="table__filter">
      <v-container fluid class="pa-0">
        <v-row class="pt-0">
          <v-col lg="4" md="6" sm="12" cols="12" class="pt-0">
            <v-autocomplete
              :items="itemProfile"
              :label="$t('settings.profile')"
              item-text="email"
              item-value="id"
              return-object
              class="pt-0"
              @change="
                getFilterData(...arguments, objectVehicle, sortOptions, page)
              "
            />
          </v-col>
          <v-col lg="4" md="6" sm="12" cols="12" class="pt-0">
            <v-autocomplete
              class="pt-0"
              :label="$t('table.vehicle')"
              :items="itemVehicle"
              item-text="state_number"
              item-value="id"
              return-object
              @change="
                getFilterData(objectProfile, ...arguments, sortOptions, page)
              "
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <table-new
      :headers="BOOKFIELDS"
      :items="BOOKLIST"
      :counts="COUNT"
      @onPagination="
        getFilterData(objectProfile, objectVehicle, sortOptions, ...arguments)
      "
      @sortBy="getFilterData(objectProfile, objectVehicle, ...arguments, page)"
      :page="page"
      :load="load"
    />
  </div>
</template>

<script>
import tableNew from "@/components/TableNew";
import total from "@/components/Total.vue";
import api from "@/api";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "Reports",
  components: { tableNew, total },

  data: () => {
    return {
      itemProfile: [{ text: "lb", value: 1 }],
      itemVehicle: [{ text: "lb", value: 1 }],
      objectProfile: undefined,
      objectVehicle: undefined,
      page: 1,
      sortOptions: { desc: [false], ordering_field: ["id"] },
      load: true
    };
  },
  computed: {
    ...mapGetters(["BOOKLIST", "BOOKFIELDS", "COUNT"])
  },
  methods: {
    ...mapActions(["getBookList"]),
    getFilterData(
      profile = this.objectProfile,
      vehicle = this.objectVehicle,
      sort = this.sortOptions,
      page = this.page
    ) {
      this.objectProfile = profile;
      this.objectVehicle = vehicle;
      this.sortOptions = sort;
      this.page = page;

      let desc =
        this.sortOptions.desc.length > 0 && this.sortOptions.desc[0] ? "" : "-";
      let arg = "?";
      this.page ? (arg = arg + `page=${this.page}`) : "?ordering=-id";
      this.objectProfile
        ? (arg = arg + `&driver=${this.objectProfile.id}`)
        : "";
      this.objectVehicle
        ? (arg = arg + `&vehicle=${this.objectVehicle.id}`)
        : "";
      this.sortOptions.ordering_field != ""
        ? (arg =
            arg +
            `${
              this.objectProfile || this.objectVehicle || this.page ? "&" : ""
            }ordering=${desc}${this.sortOptions.ordering_field}`)
        : "";
      this.getBookList({ book: "task", args: arg });
    }
  },

  mounted() {
    api.profile({ args: { path: "?role_carrier=true" } }).then(response => {
      if (response) {
        this.itemProfile = response.results?.data;
      }
    });
    api.vehicle({}).then(response => {
      if (response) {
        this.itemVehicle = response.results?.data;
      }
    });

    this.load = false;
  }
};
</script>

<style lang="scss"></style>
