var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reports"},[_c('div',{staticClass:"table__header"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"pa-0"},[_c('v-col',{staticClass:"pl-0",attrs:{"cols":"6"}},[_c('h2',{staticClass:"main-title"},[_vm._v(_vm._s(_vm.$t("menu.reports")))]),_c('total',{attrs:{"total":_vm.COUNT}})],1)],1)],1)],1),_c('div',{staticClass:"table__filter"},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"pt-0"},[_c('v-col',{staticClass:"pt-0",attrs:{"lg":"4","md":"6","sm":"12","cols":"12"}},[_c('v-autocomplete',{staticClass:"pt-0",attrs:{"items":_vm.itemProfile,"label":_vm.$t('settings.profile'),"item-text":"email","item-value":"id","return-object":""},on:{"change":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.getFilterData.apply(void 0, argsArray.concat( [_vm.objectVehicle], [_vm.sortOptions], [_vm.page] ))}}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"lg":"4","md":"6","sm":"12","cols":"12"}},[_c('v-autocomplete',{staticClass:"pt-0",attrs:{"label":_vm.$t('table.vehicle'),"items":_vm.itemVehicle,"item-text":"state_number","item-value":"id","return-object":""},on:{"change":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.getFilterData.apply(void 0, [ _vm.objectProfile ].concat( argsArray, [_vm.sortOptions], [_vm.page] ))}}})],1)],1)],1)],1),_c('table-new',{attrs:{"headers":_vm.BOOKFIELDS,"items":_vm.BOOKLIST,"counts":_vm.COUNT,"page":_vm.page,"load":_vm.load},on:{"onPagination":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.getFilterData.apply(void 0, [ _vm.objectProfile, _vm.objectVehicle, _vm.sortOptions ].concat( argsArray ))},"sortBy":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.getFilterData.apply(void 0, [ _vm.objectProfile, _vm.objectVehicle ].concat( argsArray, [_vm.page] ))}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }